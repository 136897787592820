import React from 'react'
import LayoutWithHeaderAndFooter from '../components/layouts/LayoutWithHeaderAndFooter'
import TermsAndConditionsContent from '../components/TermsAndConditionsContent'
import SEO from '../components/seo'

const TermsAndConditionsPage = (): JSX.Element => (
  <LayoutWithHeaderAndFooter>
    <>
      <SEO title="Terms and Conditions" />
      <TermsAndConditionsContent />
    </>
  </LayoutWithHeaderAndFooter>
)

export default TermsAndConditionsPage
