import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../constants/i18n'
import { DisplayMedium, LabelSmall, LabelMedium } from './typography'

const TermsAndConditionsContent = (): JSX.Element => {
  const { oxfordLogo } =
    useStaticQuery(graphql`
      query {
        oxfordLogo: file(relativePath: { eq: "oxford-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `) || {}

  const { t } = useTranslation(Namespace.TermsAndConditions)

  const contents = t('contents', { returnObjects: true }) as Array<{
    topic: string
    paragraph: string[]
  }>

  return (
    <div className="break-words">
      <div className="flex flex-row">
        <div className="flex-1">
          <DisplayMedium className="mb-2">
            {t('title.name')}
            <br />
            {t('title.informationSheet')}
          </DisplayMedium>
          <LabelSmall className="mb-8">{t('version')}</LabelSmall>
        </div>
        <div className="pl-2 w-20 md:w-32">
          {oxfordLogo && (
            <Img fluid={oxfordLogo.childImageSharp.fluid} alt="" />
          )}
        </div>
      </div>

      <LabelMedium className="mb-6">{t('referenceNumber')}</LabelMedium>

      {Array.isArray(contents) &&
        contents.map(({ topic, paragraph }, contentIndex) => (
          <Fragment key={topic}>
            <LabelMedium className="mt-10 mb-4">{topic}</LabelMedium>

            {paragraph.map((text, paragraphIndex) => (
              <p className="mb-4" key={paragraphIndex}>
                <Trans
                  t={t}
                  i18nKey={`contents.${contentIndex}.paragraph.${paragraphIndex}`}
                  components={{
                    a: (
                      <a
                        className="text-blue-500 underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                >
                  {text}
                </Trans>
              </p>
            ))}
          </Fragment>
        ))}
    </div>
  )
}

export default TermsAndConditionsContent
